
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { getStaticProps as __Next_Translate_old_getStaticProps__195a82015a1__ } from "@modules/Pages/resourceCategories/pages";
import CustomPage from "@modules/Pages";
export { getStaticPaths } from "@modules/Pages/resourceCategories/pages";
export default CustomPage;

    async function __Next_Translate__getStaticProps__195a82015a1__(ctx) {
      const res = await __Next_Translate_old_getStaticProps__195a82015a1__(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/pages/[slug]/[country]/[currency]/index',
            loaderName: 'getStaticProps',
            
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__195a82015a1__ as getStaticProps }
  